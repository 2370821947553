import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import * as React from "react"
import Bio from "../components/bio"
import Layout from "../components/layout"
import PrivateSection from "../components/privateSection"
import Seo from "../components/seo"
import { constructUrl } from "../components/util"
import { AuthContext } from "../context/auth"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next, ogimage } = data

  const { user } = React.useContext(AuthContext)

  let postDate
  if (post.frontmatter.updated) {
    postDate = post.frontmatter.updated
  } else {
    postDate = post.frontmatter.date
  }

  let origin
  if (post.frontmatter.canonical) {
    origin = post.frontmatter.canonical
  } else {
    origin = constructUrl(
      data.site.siteMetadata.siteUrl,
      post.frontmatter.permalink
    )
  }

  const ogimagesrc = getSrc(getImage(ogimage.frontmatter.ogimage))

  console.log("OGIMAGE: " + ogimagesrc)

  let [memberContent, setMemberContent] = React.useState("")

  if (post.frontmatter.tier === "member" && user) {
    import(
      `../../content/hub${post.frontmatter.permalink.slice(
        0,
        post.frontmatter.permalink.length - 1 // remove trailing slash
      )}--member.mdx`
    ).then(markdown => {
      setMemberContent(String(markdown.default))
    })
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        canonical={post.frontmatter.canonical}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        imageUrl={constructUrl(data.site.siteMetadata.siteUrl, ogimagesrc)}
        imageAlt={data.markdownRemark.frontmatter.thumbnail_alt}
      />
      <article
        className="blog-post content"
        itemScope
        itemType="http://schema.org/Article"
      >
        <GatsbyImage
          image={getImage(post.frontmatter.thumbnail)}
          alt={post.frontmatter.thumbnail_alt}
          className="postHeader"
        />
        <header className="pt-3">
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        {post.frontmatter.tier === "member" && (
          <>
            <PrivateSection html={memberContent} />
            <br />
          </>
        )}
        <p>
          <i>
            This article first appeared on{" "}
            <a href={origin}>{data.site.siteMetadata.siteUrl}</a>. Last updated:{" "}
            {postDate}
          </i>
        </p>
        <Bio />
      </article>
      <nav className="blog-post-nav content">
        <hr />
        <h3>Read more articles</h3>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link
                to={previous.fields.slug}
                rel="prev"
                className="btn btn-primary"
              >
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                className="btn btn-primary"
                to={next.fields.slug}
                rel="next"
              >
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        updated(formatString: "MMMM DD, YYYY")
        description
        canonical
        permalink
        thumbnail_alt
        tier
        thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    ogimage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        ogimage {
          childImageSharp {
            gatsbyImageData(width: 1200)
          }
        }
      }
    }
    previous: markdownRemark(
      id: { eq: $previousPostId }
      frontmatter: { status: { eq: "publish" } }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(
      id: { eq: $nextPostId }
      frontmatter: { status: { eq: "publish" } }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
